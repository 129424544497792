<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Adicione, atualize e delete fornecedores de produtos. Utilizado para filtrar e emitir notas fiscais.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md mb-3">
							<label><i class="far fa-truck color-theme font-11 me-1"></i> Razão social/Nome/CNPJ</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarFornecedores" placeholder="Busque por razão social do fornecedor, nome ou CNPJ" />
                  </div>
						<div class="col-md-4 col-xl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarFornecedores"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar fornecedores/beneficiários')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)">
										<i class="far fa-plus font-13 me-1"></i> Adicionar
									</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<fornecedor v-for="(fornecedor, index) in pesquisa.retorno.resultado" :key="index" :fornecedor="fornecedor" :index="index" @editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalFornecedor -->
      <div class="modal fade" id="modalFornecedor" tabindex="-1" aria-labelledby="modalFornecedorLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalFornecedorLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} fornecedor {{ modal.id == null ? '' : ('('+ modal.nomeFantasia +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="accordion" id="accordionExample">
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingOne">
									<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										<i class="fal fa-info-circle"></i> Informações básicas
									</button>
								</h2>
								<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-truck color-theme font-12 me-1"></i> Nome *</label>
												<input type="text" class="form-control" v-model="modal.nomeFantasia" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF/CNPJ *</label>
												<input type="text" class="form-control" v-model="modal.cpfCnpj" v-mask="['###.###.###-##', '##.###.###/####-##']" />
											</div>
											<div class="col-sm-6 mb-3" v-if="modal.pessoaFisica != true">
												<label><i class="far fa-building color-theme font-11 me-1"></i> Razão social <span v-if="modal.pessoaFisica != true">*</span></label>
												<input type="text" class="form-control" v-model="modal.razaoSocial" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3" v-if="modal.pessoaFisica != true">
												<label><i class="far fa-calculator color-theme font-11 me-1"></i> Inscrição estadual</label>
												<input type="text" class="form-control" :value="'ISENTO'" disabled v-if="modal.pessoaFisica" />
												<input type="text" class="form-control" v-model="modal.inscricaoEstadual" v-mask="'#########################'" v-else />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email</label>
												<input type="text" class="form-control" v-model="modal.email" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3 align-self-center">
												<label><span class="d-none d-sm-block">&nbsp;</span></label>
												<div class="form-check mb-0">
													<input type="checkbox" class="form-check-input" id="pessoaFisica" v-model="modal.pessoaFisica">
													<label class="form-check-label" for="pessoaFisica"><i class="far fa-male font-13 color-theme mx-1"></i> Pessoa fisíca</label>
												</div>
											</div>
											<div class="col-12">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Observações</label>
												<textarea class="form-control" v-model="modal.observacoes" maxlength="200"></textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingTwo">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
										<i class="fal fa-map-marker-alt me-3"></i>Endereço
									</button>
								</h2>
								<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-5 mb-3">
												<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
												<div class="input-group">
													<input type="text" class="form-control" v-model="modal.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
													<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
														<i class="fas fa-search"></i>
													</a>
												</div>
											</div>
											<div class="col-sm-7 mb-3">
												<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
												<input type="text" class="form-control" v-model="modal.endereco.rua" maxlength="200" />
											</div>
											<div class="col-sm-5 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
												<input type="text" class="form-control" v-model="modal.endereco.numero" maxlength="200" />
											</div>
											<div class="col-sm-7 mb-3">
												<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
												<input type="text" class="form-control" v-model="modal.endereco.bairro" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
												<input type="text" class="form-control" v-model="modal.endereco.cidade" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
												<v-select name="Desktop" :options="estados" v-model="modal.endereco.estado" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
												<select name="Mobile" class="form-control" v-model="modal.endereco.estado">
													<option v-for="(option, index) in estados" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-sm-5 mb-3 mb-sm-0">
												<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
												<input type="text" class="form-control" v-model="modal.endereco.codMunicipio" maxlength="200" />
											</div>
											<div class="col-sm-7">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
												<input type="text" class="form-control" v-model="modal.endereco.complemento" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingThree">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
										<i class="fal fa-phone-alt"></i> Telefone
									</button>
								</h2>
								<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-3 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
												<input type="text" class="form-control" v-model="modal.telefone.ddd" v-mask="['(##)', '(###)']" />
											</div>
											<div class="col-sm-5 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
												<input type="text" class="form-control" v-model="modal.telefone.telefone" v-mask="['####-####', '#####-####']" />
											</div>
											<div class="col-sm-4 mb-3 mb-sm-0">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
												<v-select name="Desktop" :options="['Fixo', 'Celular']" v-model="modal.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
												<select name="Mobile" class="form-control" v-model="modal.telefone.tipo">
													<option v-for="(option, index) in ['Fixo', 'Celular']" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-12">
												<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
												<input type="text" class="form-control" v-model="modal.telefone.nomeContato" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarFornecedor">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import fornecedor from '@/components/fiscal/fornecedores/Fornecedor.vue'

export default {
	name: 'Fornecedores',
	data: function () {
		return {
			pesquisa: {'nome': '', 'retorno': {'nome': null, 'resultado': []}},
			modal: {
				'id': null, 'cpfCnpj': null, 'inscricaoEstadual': null, 'nomeFantasia': null, 'razaoSocial': null, 'pessoaFisica': false, 'email': null, 'regimeTributario': null,
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'observacoes': ''
			}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         estados: state => state.estados,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		fornecedor
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Fornecedores',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nomeFantasia', 'razaoSocial', 'pessoaFisica', 'cpfCnpj', 'email'], 
				'lista': null
			})
		},
		abrirModal : function (fornecedor) {
			if (fornecedor == null) {
				Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			} else {
				this.modal = {
					'id': fornecedor.id, 
					'cpfCnpj': fornecedor.cpfCnpj, 
					'inscricaoEstadual': fornecedor.inscricaoEstadual, 
					'nomeFantasia': fornecedor.nomeFantasia, 
					'razaoSocial': fornecedor.razaoSocial, 
					'pessoaFisica': fornecedor.pessoaFisica, 
					'email': fornecedor.email, 
					'regimeTributario': fornecedor.regimeTributario, 
					'endereco': fornecedor.endereco, 
					'telefone': fornecedor.telefone,
					'observacoes': fornecedor.observacoes
				}
			}

			$('#modalFornecedor').modal('show')
		},
		buscarFornecedores : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/searchFornecedores',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarCEP : function () {
         if (String(this.modal.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modal.endereco.cep).then((data) => {
				this.modal.endereco.rua = data.logradouro
				this.modal.endereco.complemento = data.complemento
				this.modal.endereco.bairro = data.bairro
				this.modal.endereco.cidade = data.localidade
				this.modal.endereco.estado = data.uf
				this.modal.endereco.codMunicipio = data.ibge
			})
      },
		salvarFornecedor : function () {
			if (this.modal.cpfCnpj == null || String(this.modal.cpfCnpj).trim().length == 0 ||
				this.modal.nomeFantasia == null || String(this.modal.nomeFantasia).trim().length == 0 ||
				(this.modal.pessoaFisica != true && (this.modal.razaoSocial == null || String(this.modal.razaoSocial).trim().length == 0))) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			if (this.modal.pessoaFisica) this.modal.inscricaoEstadual = 'ISENTO'

			this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/saveFornecedor',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalFornecedor').modal('hide')
				this.buscarFornecedores()

				this.$toast.fire({
					icon: 'success',
					title: 'Fornecedor salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'CPF/CNPJ já cadastrado!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>
